
    @font-face {
        font-family: 'DejaVu_sans';
        src: local('bpg_dejavu_sans'), local('bpg_dejavu_sans'),
        url(./bpg_dejavu_sans.woff) format('woff2'),
        url(./bpg_dejavu_sans.woff2) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: 'DejaVu_sans_bold';
        src: local('DejaVuSans-Bold.woff2'), local('DejaVuSans-Bold.woff2'),
        url(./DejaVuSans-Bold.woff) format('woff2'),
        url(./DejaVuSans-Bold.woff2) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: 'Nino_Mtavruli_Normal';
        src: local('BPGNinoMtavruli.woff2'), local('BPGNinoMtavruli.woff2'),
        url(./BPGNinoMtavruli.woff) format('woff2'),
        url(./BPGNinoMtavruli.woff2) format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: fallback;
    }

    @font-face {
        font-family: 'Nino_Mtavruli_Normal_Bold';
        src: local('BPGNinoMtavruli-Bold.woff2'), local('BPGNinoMtavruli-Bold.woff2'),
        url(./BPGNinoMtavruli-Bold.woff) format('woff2'),
        url(./BPGNinoMtavruli-Bold.woff2) format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: fallback;
    }

    @font-face {
        font-family: 'FiraGO-Regular';
        src: local('FiraGO-Regular.woff2'), local('FiraGo-Regular.woff2'),
        url(./FiraGO-Regular.woff) format('woff'),
        url(./FiraGO-Regular.woff2) format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: 'FiraGO-Bold';
        src: local('FiraGO-Bold.woff2'), local('FiraGo-Bold.woff2'),
        url(./FiraGO-Bold.woff) format('woff'),
        url(./FiraGO-Bold.woff2) format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: 'FiraGO-Light';
        src: local('FiraGO-Light.woff2'), local('FiraGo-Light.woff2'),
        url(./FiraGO-Light.woff) format('woff'),
        url(./FiraGO-Light.woff2) format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }