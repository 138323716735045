
.react-multi-carousel-track {
    padding-bottom: 15px;
}
.react-multi-carousel-dot-list {
    /* justify-content: flex-end; */
    margin-right: 50px;
    /* margin-bottom: 20px; */
    align-items: center; 
}                           
.inactive{
    height: 7px;
    width: 7px;
    margin-top: 2px;
    background-color: #C5C6CD;/* #E5E6ED; */
    border-radius: 50%;
    outline: none;
    border: unset;
}
.inactive:focus{
    outline: none;
    border: unset;
}
.active{
    height: 10px;
    width: 10px;
    background-color: #261e56;
    border-radius: 50%;
    outline: none;
    border: unset;
}
.active:focus{
    outline: none;
    border: unset;
}
