.MuiAccordionDetails-root {
    padding: unset !important;
}
.MuiBox-root{
    padding: unset !important;
}
.MuiAccordionSummary-content {
    margin: unset !important;
    box-shadow: unset !important;
}
.MuiTypography-root{
    width: 100%;
}
.makeStyles-root-8{
    height: unset !important;
    transition: all 300ms;
}
